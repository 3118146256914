'use client';

import React, { useState } from 'react';
import {
    TextField,
    Button,
    Box,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox,
    Alert,
    Snackbar,
    Dialog,
    DialogContent,
    IconButton
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import { ConciergeInquiryMessage } from '../../../shared/models';
import { getBackendURL } from '../lib/util';
import SmallCustomCalendar from '../components/small_custom_calendar';

interface ConciergeQuestionnaireProps {
    open: boolean;
    onClose: () => void;
}

export default function ConciergeQuestionnaire({ open, onClose }: ConciergeQuestionnaireProps) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [dateRequested, setDateRequested] = useState<Date | null>(null);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [numberOfGuests, setNumberOfGuests] = useState('');
    const [flexibility, setFlexibility] = useState('');
    const [locationPreference, setLocationPreference] = useState('');
    const [venueTypePreference, setVenueTypePreference] = useState('');
    const [message, setMessage] = useState('');
    const [sending, setSending] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [consentChecked, setConsentChecked] = useState(true);
    const [showCalendar, setShowCalendar] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [errors, setErrors] = useState({
        email: '',
        phone: '',
        name: '',
        numberOfGuests: '',
        preferredDate: '',
        preferredTime: ''
    });

    const validateEmail = (email: string) => {
        if (!email) return true;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {
            email: '',
            phone: '',
            name: '',
            numberOfGuests: '',
            preferredDate: '',
            preferredTime: ''
        };

        if (!name.trim()) {
            newErrors.name = 'Name is required';
            isValid = false;
        }

        if (!email && !phone) {
            newErrors.email = 'Please provide either an email or phone number';
            newErrors.phone = 'Please provide either an email or phone number';
            isValid = false;
        } else {
            if (email && !validateEmail(email)) {
                newErrors.email = 'Please enter a valid email address';
                isValid = false;
            }
        }

        if (!numberOfGuests) {
            newErrors.numberOfGuests = 'Number of guests is required';
            isValid = false;
        }

        if (!dateRequested) {
            newErrors.preferredDate = 'Preferred date is required';
            isValid = false;
        }

        if (!startTime || !endTime) {
            newErrors.preferredTime = 'Preferred time is required';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!validateForm()) return;
        setSending(true);

        try {
            const inquiry: ConciergeInquiryMessage = {
                Name: name,
                Email: email,
                Phone: phone,
                Flexibility: flexibility,
                LocationPreference: locationPreference,
                VenueTypePreference: venueTypePreference,
                Message: message,
                DateRequested: dateRequested ? dateRequested.toISOString().split('T')[0] : '',
                TimeRequested: `${startTime} - ${endTime}`,
                NumberOfGuests: parseInt(numberOfGuests),
                CreatedAt: new Date(),
                Budget: '',
                MustHaves: '',
            };

            const response = await fetch(`${getBackendURL('startConciergeInquiry')}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(inquiry)
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Failed to send message');
            }

            setShowSuccess(true);
            setIsSubmitted(true);
        } catch (error) {
            console.error('Error sending inquiry:', error);
        } finally {
            setSending(false);
        }
    };

    const commonTextFieldStyles = {
        mb: 2,
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
            color: '#F0D1FF',
            borderRadius: '12px',
            '& fieldset': {
                borderColor: 'rgba(240, 209, 255, 0.3)',
            },
            '&:hover fieldset': {
                borderColor: '#E0B1F1',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#E0B1F1',
            }
        },
        '& .MuiInputLabel-root': {
            color: '#F0D1FF',
            fontFamily: 'Sans'
        }
    };

    const generateTimeOptions = () => {
        const times = [];
        for (let hour = 6; hour < 30; hour++) {
            const actualHour = hour % 24;
            const period = actualHour >= 12 ? 'PM' : 'AM';
            const displayHour = actualHour === 0 ? 12 : actualHour > 12 ? actualHour - 12 : actualHour;
            const time = `${displayHour}:00 ${period}`;
            times.push(time);
        }
        return times;
    };

    const timeOptions = generateTimeOptions();

    const handleDateSelection = (date: Date) => {
        setDateRequested(date);
        setShowCalendar(false);
    };

    const handleClose = () => {
        if (!sending) {
            onClose();
            // Reset form after a delay to avoid visual glitches during closing animation
            setTimeout(() => {
                if (isSubmitted) {
                    setIsSubmitted(false);
                    setName('');
                    setEmail('');
                    setPhone('');
                    setDateRequested(null);
                    setStartTime('');
                    setEndTime('');
                    setNumberOfGuests('');
                    setFlexibility('');
                    setLocationPreference('');
                    setVenueTypePreference('');
                    setMessage('');
                    setShowSuccess(false);
                    setConsentChecked(true);
                }
            }, 300);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    backgroundColor: '#2c2c54',
                    borderRadius: '16px',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
                    overflow: 'hidden',
                    maxHeight: '90vh'
                }
            }}
        >
            <DialogContent sx={{ p: 0, overflow: 'auto' }}>
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: '#F0D1FF',
                        zIndex: 1
                    }}
                >
                    <CloseIcon />
                </IconButton>

                {isSubmitted ? (
                    <Box
                        sx={{
                            padding: { xs: 4, sm: 6 },
                            backgroundColor: 'transparent',
                            minHeight: '50vh',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            gap: 4
                        }}
                    >
                        <CheckCircleOutlineIcon
                            sx={{
                                fontSize: 90,
                                color: '#E0B1F1',
                                mb: 2
                            }}
                        />
                        <Typography
                            variant="h4"
                            sx={{
                                color: '#F0D1FF',
                                fontFamily: 'Thick',
                                fontSize: { xs: '1.75rem', sm: '2.5rem' },
                                fontWeight: 'bold',
                                mb: 2,
                                letterSpacing: '-0.02em'
                            }}
                        >
                            We're on it!
                        </Typography>
                        <Typography
                            sx={{
                                color: 'rgba(255, 255, 255, 0.9)',
                                fontSize: { xs: '1.1rem', sm: '1.25rem' },
                                maxWidth: '600px',
                                lineHeight: 1.6,
                                mb: 4
                            }}
                        >
                            Our venue experts are already working on finding the perfect space for your event. We'll be in touch within 24 hours with handpicked recommendations that match your requirements.
                        </Typography>
                        <Button
                            onClick={handleClose}
                            variant="contained"
                            sx={{
                                py: 2,
                                px: 6,
                                fontSize: '1.1rem',
                                borderRadius: '9999px',
                                backgroundColor: '#E0B1F1',
                                color: '#2c2c54',
                                textTransform: 'none',
                                fontWeight: 'bold',
                                fontFamily: 'Sans',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                '&:hover': {
                                    backgroundColor: '#E0B1F1',
                                    transform: 'scale(1.02)',
                                },
                                '&:active': {
                                    transform: 'scale(0.98)',
                                }
                            }}
                        >
                            Close
                        </Button>
                    </Box>
                ) : (
                    <Box sx={{
                        padding: { xs: 2.5, sm: 4 },
                        backgroundColor: 'transparent',
                    }}>
                        <Typography
                            variant="h4"
                            sx={{
                                color: '#F0D1FF',
                                mb: 2,
                                fontFamily: 'Thick',
                                fontSize: { xs: '1.5rem', sm: '2.25rem' },
                                fontWeight: 'bold',
                                letterSpacing: '-0.02em',
                                mt: 2
                            }}
                        >
                            Let's find your perfect venue
                        </Typography>

                        <Typography
                            sx={{
                                color: 'rgba(255, 255, 255, 0.85)',
                                mb: { xs: 3, sm: 4 },
                                fontSize: { xs: '1rem', sm: '1.25rem' },
                                lineHeight: 1.6,
                                maxWidth: '650px'
                            }}
                        >
                            Share a few details with us, and we'll handpick venues that match your vision perfectly.
                        </Typography>

                        <Box sx={{
                            mb: 4,
                            display: 'flex',
                            gap: 2,
                            flexWrap: 'wrap'
                        }}>
                            {[
                                { icon: '🎯', text: 'Personalized recommendations' },
                                { icon: '⚡', text: 'Quick response time' },
                                { icon: '💎', text: 'No fees and the lowest prices, guaranteed' }
                            ].map((item) => (
                                <Box key={item.text} sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    backgroundColor: 'rgba(255, 255, 255, 0.08)',
                                    padding: '8px 16px',
                                    borderRadius: '100px',
                                    color: '#F0D1FF'
                                }}>
                                    <span>{item.icon}</span>
                                    <Typography sx={{ fontSize: '0.9rem' }}>{item.text}</Typography>
                                </Box>
                            ))}
                        </Box>

                        <Typography
                            sx={{
                                color: '#F0D1FF',
                                mb: 3,
                                fontFamily: 'Thick',
                                fontSize: { xs: '1.1rem', sm: '1.3rem' },
                                fontWeight: 'bold'
                            }}
                        >
                            Tell us about your event
                        </Typography>

                        <form onSubmit={handleSubmit}>
                            <TextField
                                fullWidth
                                label="Your Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                error={!!errors.name}
                                helperText={errors.name}
                                required
                                sx={commonTextFieldStyles}
                            />

                            <TextField
                                fullWidth
                                label="Your Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                error={!!errors.email}
                                helperText={errors.email}
                                sx={commonTextFieldStyles}
                            />

                            <TextField
                                fullWidth
                                label="Your Phone Number"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                error={!!errors.phone}
                                helperText={errors.phone}
                                sx={commonTextFieldStyles}
                            />

                            <Box sx={{ mb: 2 }}>
                                {!showCalendar && (
                                    <Button
                                        onClick={() => setShowCalendar(true)}
                                        sx={{
                                            width: '100%',
                                            py: 1.5,
                                            backgroundColor: 'rgba(255, 255, 255, 0.08)',
                                            color: '#F0D1FF',
                                            borderRadius: '12px',
                                            border: '1px solid rgba(240, 209, 255, 0.3)',
                                            textTransform: 'none',
                                            justifyContent: 'flex-start',
                                            pl: 2,
                                            '&:hover': {
                                                backgroundColor: 'rgba(255, 255, 255, 0.12)',
                                                borderColor: '#E0B1F1',
                                            }
                                        }}
                                    >
                                        {dateRequested ? dateRequested.toLocaleDateString() : 'Select Date for Event'}
                                    </Button>
                                )}
                                {showCalendar && (
                                    <SmallCustomCalendar
                                        selectedDate={dateRequested || new Date()}
                                        onDateChange={handleDateSelection}
                                        minDate={new Date()}
                                    />
                                )}
                            </Box>

                            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                                <FormControl fullWidth>
                                    <InputLabel sx={{ color: '#F0D1FF' }}>Start Time</InputLabel>
                                    <Select
                                        value={startTime}
                                        onChange={(e) => setStartTime(e.target.value)}
                                        required
                                        sx={{
                                            backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                            color: '#F0D1FF'
                                        }}
                                    >
                                        {timeOptions.map((time) => (
                                            <MenuItem key={time} value={time}>{time}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth>
                                    <InputLabel sx={{ color: '#F0D1FF' }}>End Time</InputLabel>
                                    <Select
                                        value={endTime}
                                        onChange={(e) => setEndTime(e.target.value)}
                                        required
                                        sx={{
                                            backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                            color: '#F0D1FF'
                                        }}
                                    >
                                        {timeOptions.map((time) => (
                                            <MenuItem key={time} value={time}>{time}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>

                            <TextField
                                fullWidth
                                label="Number of Guests"
                                value={numberOfGuests}
                                onChange={(e) => setNumberOfGuests(e.target.value)}
                                required
                                error={!!errors.numberOfGuests}
                                helperText={errors.numberOfGuests}
                                sx={commonTextFieldStyles}
                            />

                            <TextField
                                fullWidth
                                label="Location Preference"
                                value={locationPreference}
                                onChange={(e) => setLocationPreference(e.target.value)}
                                sx={commonTextFieldStyles}
                            />

                            <TextField
                                fullWidth
                                label="Venue Type Preference(Bar, Restaurant, Rawspace, etc.)"
                                value={venueTypePreference}
                                onChange={(e) => setVenueTypePreference(e.target.value)}
                                sx={commonTextFieldStyles}
                            />

                            <TextField
                                fullWidth
                                label="Additional Details, must haves, nice to haves, etc."
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                multiline
                                rows={4}
                                sx={commonTextFieldStyles}
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={consentChecked}
                                        onChange={(e) => setConsentChecked(e.target.checked)}
                                        sx={{ color: '#F0D1FF' }}
                                    />
                                }
                                label={
                                    <Typography sx={{ color: '#F0D1FF', fontSize: '14px' }}>
                                        I agree to receive messages from Litty related to this inquiry
                                    </Typography>
                                }
                            />

                            <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                                disabled={sending || !consentChecked}
                                sx={{
                                    py: 1.5,
                                    px: 4,
                                    fontSize: '1rem',
                                    borderRadius: '9999px',
                                    backgroundColor: '#E0B1F1',
                                    color: '#2c2c54',
                                    textTransform: 'none',
                                    fontWeight: 'bold',
                                    fontFamily: 'Sans',
                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                    mt: 3,
                                    mb: 2,
                                    '&:hover': {
                                        backgroundColor: '#E0B1F1',
                                        transform: 'scale(1.02)',
                                    },
                                    '&:active': {
                                        transform: 'scale(0.98)',
                                    },
                                    '&:disabled': {
                                        backgroundColor: 'rgba(224, 177, 241, 0.5)',
                                        color: 'rgba(44, 44, 84, 0.5)'
                                    }
                                }}
                            >
                                {sending ? 'Sending...' : 'Submit Questionnaire'}
                            </Button>
                        </form>
                    </Box>
                )}

                <Snackbar
                    open={showSuccess}
                    autoHideDuration={6000}
                    onClose={() => setShowSuccess(false)}
                >
                    <Alert
                        severity="success"
                        icon={<CheckCircleOutlineIcon />}
                        sx={{ width: '100%' }}
                    >
                        Questionnaire submitted successfully!
                    </Alert>
                </Snackbar>
            </DialogContent>
        </Dialog>
    );
}